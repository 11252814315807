<template>
  <div class="about">
    <div class="setting">
      <div class="settingPwd" @click="setPwd">
        <van-icon name="setting" />
        <span class="text">修改密码</span>
        <van-icon name="arrow" />
      </div>
      <div class="outLogin" @click="outLogin">
        <span class="text">退出登录</span>
      </div>
    </div>

    <van-dialog
      v-model:show="showChangePW"
      title="修改密码"
      :show-confirm-button="false"
    >
      <div class="form">
        <van-form @submit="changePassword">
          <van-field
            v-model="password"
            type="password"
            name="password"
            label="原密码"
            placeholder="原密码"
            :rules="[{ required: true, message: '请填写原密码' }]"
          />
          <van-field
            v-model="password2"
            type="password"
            name="password2"
            label="新密码"
            placeholder="新密码"
            :rules="[{ required: true, message: '请填写新密码' }]"
          />
          <van-field
            v-model="password2Confirm"
            type="password"
            name="password2Confirm"
            label="确认密码"
            placeholder="确认密码"
            :rules="[{ required: true, message: '请填写确认密码' }]"
          />
          <div style="margin: 16px">
            <van-button round block color="#1baeae" native-type="submit"
              >确认修改</van-button
            >
          </div>
          <div style="margin: 0 16px 16px">
            <van-button round block @click="showChangePW = false"
              >取消</van-button
            >
          </div>
        </van-form>
      </div>
    </van-dialog>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { Dialog, Toast } from "vant";
import { clearLocal } from "@/common/js/utils";
import { apiChangePassword } from "@/service/user";
import { logout } from "@/service/user";

const router = useRouter();

let showChangePW = ref(false);
let password = ref("");
let password2 = ref("");
let password2Confirm = ref("");

// 退出登录

const outLogin = () => {
  clickStyle("outLogin");
  Dialog.confirm({
    title: "确认退出登录？",
    message: "退出成功后，返回登录页面！",
  })
    .then(async () => {
      await logout({});
      clearLocal();
      router.push({
        name: "login",
      });
    })
    .catch(() => {});
};

// 修改密码
const setPwd = () => {
  clickStyle("settingPwd");
  showChangePW.value = true;
};

const changePassword = async (values) => {
  if (values.password2Confirm != values.password2) {
    Toast.fail("确认密码与新密码不一致！");
    return false;
  }

  const apiRes = await apiChangePassword(values);
  if (apiRes) {
    Toast.success("修改成功，请重新登录！");
    clearLocal();
    router.push({
      name: "login",
    });
  }
};

// 点击效果方法
const clickStyle = (className) => {
  let _setting = document.getElementsByClassName(className)[0];
  _setting.style.backgroundColor = "rgb(237,237,237)";
  setTimeout(() => {
    _setting.style.backgroundColor = "#fff";
  }, 100);
};
</script>

<style lang="less" scoped>
.setting {
  .settingPwd {
    padding: 0 5px;
    line-height: 30px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    :deep(.van-icon-arrow) {
      flex: 1;
      text-align: right;
    }
    .text {
      margin-left: 5px;
    }
  }

  .outLogin {
    line-height: 30px;
    text-align: center;
    color: red;
    font-weight: 600;
    font-size: 10px;
  }
}
</style>
