<template>
  <div class="about">
    <s-header />
    <n-bar :userType="'student'" :activeItemName="'info'" />
    <support />

    <div class="pageContent">
      <div class="headerUserInfo">
        <u-serinfo :userInfo="userInfo"></u-serinfo>
      </div>
      <div class="userInfo">
        <div class="other" @click="handelClick">
          <van-icon name="cluster" />
          <span class="text">其他设置</span>
          <van-icon name="arrow" />
        </div>
        <s-etting></s-etting>
      </div>
    </div>
  </div>
</template>

<script setup>
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import uSerinfo from "@/components/UserInfo.vue";
import sEtting from "@/components/Setting.vue";
import { getLocal } from "@/common/js/utils";
import { Toast } from "vant";

const userInfo = JSON.parse(getLocal("userInfo"));

const handelClick = () => {
  Toast.fail("该功能未开放！");
};
</script>

<style lang="less" scoped>
@import "@/common/style/mixin.less";

.pageContent {
  padding-top: 10px;
  .userInfo {
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
    background-color: @fc;
    margin-bottom: 10px;
  }

  .other {
    line-height: 30px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    :deep(.van-icon-arrow) {
      flex: 1;
      text-align: right;
    }
    .text {
      margin-left: 5px;
    }
  }
}

.headerUserInfo {
  background-color: #fff;
  width: 80%;
  max-width: 400px;
  margin: 0 auto;
  margin-bottom: 10px;
  border-radius: 10px;
  div {
    line-height: 30px;
    // padding:0 5px;
    border-bottom: 1px solid rgb(237, 237, 237);
    .text {
      margin-left: 5px;
      margin-right: 20px;
    }
  }
}

/* 竖屏 */
@media screen and (orientation: portrait) {
}

/* 手机竖屏 */
@media screen and (orientation: portrait) and (max-device-width: 420px) {
  .pageContent {
    padding-top: 0;
    min-height: calc(100vh - 30px - 50px);
    .userInfo {
      width: 100%;
      padding: 0;
    }
  }
  .headerUserInfo {
    width: 100%;
  }
}

/* 横屏 */
@media screen and (orientation: landscape) {
}
</style>
